<template>
    <div class="comNews">
        <IndexTop></IndexTop>
        <div class="elcar-img">
            <img src="../static/policy/da2.jpg" alt="">
        </div>
        <div class="comNews-content" v-loading="loading">
            <ul class="sort">
                <li v-for="(item, index) in liarr" :key="index" @click="getComNews(item.sortId)"
                    :class="{ sli: item.sortId == recept }">
                    {{ item.title }}
                </li>
            </ul>
            <div class="content">
                <div v-for="item in comNewsList" class="content-list" :key="item.id">
                    <div class="content-list-left">
                        <span>{{ item.create_t }}</span>
                        <span style="font-weight: bold;">{{ item.title }}</span>
                        <p v-html="item.content"></p>
                        <el-button type="primary" text bg @click="$router.push(`/newsDetail?id=${item.id}`)">
                            查看详情
                        </el-button>
                    </div>
                    <div class="content-list-right" @click="$router.push(`/newsDetail?id=${item.id}`)">
                        <img src="../static/policy/xiao.jpg" alt="">
                    </div>
                </div>
            </div>
            <div class="content-page">
                <el-pagination v-model:current-page="currentPage1" :page-size="pageSize" :small="small"
                    :disabled="disabled" :background="background" layout="total, prev, pager, next" :total="total"
                    @current-change="handleCurrentChange" />
            </div>

        </div>

        <IndexFoot></IndexFoot>
    </div>
</template>
<script setup>
import IndexTop from '../components/indexTop.vue'
import IndexFoot from '../components/indexFoot.vue'
import { ref, reactive, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { comsortPolicy, getPagePolicy } from '../api/policy'
const router = useRouter()
const currentPage1 = ref(1)
const pageSize = ref(6)
const total = ref(0)
const loading = ref(true)
const comNewsList = ref([])
const liarr = reactive(
    [{
        title: '发改委',
        sortId: 7
    },
    {
        title: '工业和信息化',
        sortId: 8
    },
    {
        title: '科学技术',
        sortId: 9
    }
    ]
)
let recept = ref(7)
onMounted(() => {
    recept.value = router.currentRoute.value.query.sortId

    let params = {
        sort_id: recept.value
    }

    let params1 = {
        sort_id: recept.value,
        page_size: pageSize.value,
        current_page: currentPage1.value
    }

    comsortPolicy(params).then(res => {
        total.value = res.data.length
        loading.value = false
    })

    getPagePolicy(params1).then(res => {
        comNewsList.value = res.data

    })

})

function getComNews(sortId) {
    loading.value = true
    recept.value = sortId
    currentPage1.value = 1
    let params = {
        sort_id: sortId,
        page_size: pageSize.value,
        current_page: currentPage1.value
    }
    let params1 = {
        sort_id: sortId
    }
    getPagePolicy(params).then(res => {
        comNewsList.value = res.data
    })

    comsortPolicy(params1).then(res => {
        total.value = res.data.length
        loading.value = false
    })
}

function handleCurrentChange() {
    loading.value = true
    let params1 = {
        sort_id: recept.value,
        current_page: currentPage1.value,
        page_size: pageSize.value
    }
    getPagePolicy(params1).then(res => {
        comNewsList.value = res.data
        loading.value = false
    })
}
</script>

<style scoped lang="scss">
.elcar-img {
    margin-top: 80px;
    height: calc(100vh - 80px);

    img {
        width: 100%;
        height: 100%;
    }
}

.comNews-content {
    .sort {
        padding: 0 10%;
        margin: 0;
        height: 60px;
        list-style: none;
        display: flex;
        background-color: #fff;
        align-items: center;

        li {
            margin-right: 20px;
        }

        .sli {
            font-weight: bold;
            color: #003E9F;
        }

        li:hover {
            font-weight: bold;
            color: #003E9F;
            cursor: pointer;
        }
    }

    .content-page {
        margin-top: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .content {
        width: 100%;
        margin: 0 auto;
        box-sizing: border-box;
        padding: 10px 10%;

        .content-list {

            background: #fff;
            width: 100%;
            height: 240px;
            border-bottom: 2px solid #f5f5f5;
            display: flex;
            padding: 20px;
            box-sizing: border-box;
            justify-content: space-between;

            .content-list-left {
                width: 50%;
                display: flex;
                flex-direction: column;
                justify-content: space-around;

                span:nth-child(1) {
                    font-size: 15px;
                    font-family: 'Courier New', Courier, monospace;
                    color: #666666;
                    cursor: pointer;
                }

                span:nth-child(2) {
                    cursor: pointer;
                }

                span:nth-child(2):hover {
                    color: #003E9F;
                }

                p {
                    cursor: pointer;
                    line-height: 25px;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    font-size: 16px;
                    color: #666666;
                    text-overflow: ellipsis;
                    white-space: normal;
                    font-family: 'Courier New', Courier, monospace;

                }
            }

            .content-list-right {

                width: 30%;
                overflow: hidden;

                img {
                    height: 240px;
                    width: 100%;
                    transition: transform 0.3s;
                }

                img:hover {
                    transform: scale(1.1);
                    cursor: pointer;
                }
            }
        }
    }

}
</style>