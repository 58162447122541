<template>
    <div class="digiTal">
        <IndexTop></IndexTop>
        <div class="search-box">
                <input v-model="serarchName" type="text" placeholder="请输入搜索关键字">
                <button @click="toSearch">搜索</button>
        </div>
        <div class="content">
            
            <div class="con-list" v-for="(item, index) in digiTalData" :key="index">
                <div class="content-list">
                    <div class="list-left">
                        <img :src="item.img_url" alt="">
                    </div>
                    <div class="list-center">
                        <div class="center-top">
                            <span>{{ item.title }}</span>
                            <span></span>
                        </div>
                        <p>{{ item.des }}</p>
                    </div>
                    <div class="list-right"></div>
                </div>
                <el-collapse v-model="activeName" accordion>
                    <el-collapse-item title="查看详情" :name="index">
                        <div class="detail">
                            <embed :src="item.file_url" type="application/pdf" width="100%" height="500px"><embed>
                        </div>
                    </el-collapse-item>
                </el-collapse>
            </div>
        </div>
        <IndexFoot></IndexFoot>
    </div>
</template>
<script setup>
import IndexTop from '../components/indexTop.vue'
import IndexFoot from '../components/indexFoot.vue'
import { ref, onMounted } from 'vue'
import { getDigital, getSearchDigital } from '@/api/home'
const activeName = ref('0')
const digiTalData = ref([])
const serarchName = ref('')
onMounted(() => {
    getDigital().then(res => {
        digiTalData.value = res.data
    })
})
const toSearch = () => {
    getSearchDigital(serarchName.value).then(res => {
        digiTalData.value = res.data
    })
}
</script>
<style scoped lang="scss">
.digiTal {
    .search-box {
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 500px;
        height: 40px;
        margin: 110px auto 10px;
        input{
            height: 35px;
            width: 400px;
        }
        button {
            width: 80px;
            height: 35px;
            color: #fff;
            background: #003E9F;
            cursor: pointer;
        }
    }

    .content {
        min-height: calc(100vh - 80px);
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        font-size: 20px;
        width: 960px;
        margin: 0 auto;
        flex-wrap: wrap;
        
        .con-list{
            width:470px;
        }

        .detail {
            display: flex;
            flex-direction: column;

            img {
                width: 100%;
                height: auto;
            }

        }

        .content-list {
            padding-right: 20px;
            box-sizing: border-box;
            margin-top: 20px;
            border-radius: 5px;
            background: white;
            display: flex;
            height: 200px;

            .list-left {
                width: 200px;
                flex-shrink: 0;
                margin-right: 20px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .list-center {
                .center-top {
                    margin-top: 10px;

                    :nth-child(1) {
                        font-size: 20px;
                        font-weight: bold;
                        margin-right: 20px;
                    }

                    :nth-child(2) {
                        font-size: 14px;
                        font-weight: bold;
                    }

                }

                p {
                    height: 120px;
                    font-size: 15px;
                    text-indent: 30px;
                    overflow-y: auto;
                }
            }
        }
    }
}
</style>
