<script setup>
import { ref } from 'vue'
import IndexTop from '../components/indexTop.vue'
import IndexFoot from '../components/indexFoot.vue'
const activeNames = ref(['1', '2', '3'])
</script>
<template>
    <div class="member">
        <IndexTop></IndexTop>
        <div class="content">
            <!-- <h1>收费模式</h1>
            <el-divider />
            <div>
                <span style="font-weight: bolder;font-size: 17px;">收费模式详解</span>
                <p>筑数企策数据科技有限公司为客户提供多样化的收费模式，以满足不同会员的服务需求。以下详细介绍了会员费用结构、单项服务收费与优惠政策。</p>
                <span style="font-weight: bolder;font-size: 17px;">会员费用结构</span>
                <p>V1临时会员：免费。注册即可成为临时会员，享受基础服务包括有限访问知识库、部分政策解读内容，以及AI咨询服务的短期体验。</p>
                <p>V2个人会员（800元/年）：面向个人用户，提供全面的知识库访问权限、无限次AI咨询服务，以及参与专题课程和活动的资格。</p>
                <p>V3企业会员（8000元/年）：针对企业客户设计，除包含V2会员所有权益外，还提供企业定制化服务，如人工专业咨询。</p>
                <span style="font-weight: bolder;font-size: 17px;">单项服务收费</span>
                <p>基础AI咨询服务：¥60/月。适用于需求明确的简单咨询，如单一法律、税务问题。</p>
                <p>高级人工专业咨询：¥200/小时起。针对复杂的企业管理、策略规划等问题，提供深度定制化咨询。</p>
                <p>企业资质申报服务：根据不同资质级别有不同的定价，为企业规划及申报各类资质提供全方位支持：</p>
                <p>市级科技型中小企业：免费申报。</p>
                <p>国家科技型中小企业：¥2,000。</p>
                <p>国家高新技术企业：¥20,000起。</p>
                <p>省级工程技术研究中心：¥40,000起。</p>
                <p>国家级工程技术研究中心：¥100,000起。</p>
                <p>省级专精特新企业：¥30,000起。</p>
                <p>国家级专精特新企业：¥80,000起。</p>
                <p>定制化培训和研讨会：¥1,000/人起。根据培训内容深度、讲师资质及持续时长等不同因素定价。</p>
                <span style="font-weight: bolder;font-size: 17px;">优惠政策</span>
                <p>早鸟优惠：提前预订服务或会员续费，享受最高10%的折扣。</p>
                <p>团体折扣：企业或团体购买会员或培训课程时，可获得更多折扣，具体根据团体规模调整优惠程度。</p>
                <p>续费优惠：会员续费时，提供5%的折扣优惠，旨在鼓励会员持续合作。</p>
                <p>推荐奖励：现有会员推荐新会员成功注册，双方将获得一定金额的服务抵用券。</p>
            </div>
            <el-divider /> -->
            <h1>会员权益</h1>
            <el-collapse v-model="activeNames" @change="handleChange">
                <el-collapse-item name="1">
                    <template #title>
                        <span style="font-weight: bolder;font-size: 17px;">V1 临时会员</span>
                    </template>
                    <div>
                        <p><span style="font-weight: bolder;">知识库访问：</span>限时访问政策和数据科技基础知识，帮助您建立对行业环境的基本理解。</p>
                        <p><span style="font-weight: bolder;">政策解读：</span>部分解读文章开放，为企业提供政策方向的初步指导。</p>
                        <p><span style="font-weight: bolder;">AI咨询服务体验：</span>7天AI技术咨询服务体验，涉及基础法律、税务问题解答。</p>
                        <p><span style="font-weight: bolder;">评测机会：</span>提供3次企业资质评测机会，帮助企业初步评估自己符合哪些资质要求。</p>
                        <p><span
                                style="font-weight: bolder;">AI咨询服务介绍：</span>通过先进的AI技术，我们为会员提供即时的咨询服务，覆盖法律、税务等关键领域。AI咨询通过模拟专业顾问的思考过程，为您的问题提供高效解答。
                        </p>
                    </div>
                </el-collapse-item>
                <el-collapse-item name="2">
                    <template #title>
                        <span style="font-weight: bolder;font-size: 17px;">V2 个人会员</span>
                    </template>
                    <div>
                        <p><span style="font-weight: bolder;">完整知识库访问：</span>解锁所有政策文档和深度解读，为您的企业决策提供数据支持。</p>
                        <p><span style="font-weight: bolder;">无限AI咨询服务：</span>全年无限次数的AI技术咨询服务，涵盖更广泛的专业领域。</p>
                        <p><span style="font-weight: bolder;">专题课程和活动：</span>免费参加由公司组织的所有线上专题课程和行业沙龙。</p>
                        <p><span style="font-weight: bolder;">申报辅导：</span>提供市级科技型企业申报辅导及企业数据资产咨询。</p>
                        <p><span
                                style="font-weight: bolder;">专题课程介绍：</span>我们定期组织专题课程和行业沙龙，旨在帮助会员掌握最新的行业趋势、技术变革，以及政策动向。这些活动不仅提供学习交流的机会，也是建立行业网络的良好平台。
                        </p>
                    </div>
                </el-collapse-item>
                <el-collapse-item name="3">
                    <template #title>
                        <span style="font-weight: bolder;font-size: 17px;">V3 企业会员</span>
                    </template>
                    <div>
                        <p><span style="font-weight: bolder;">人工专业咨询：</span>AI服务之外，提供人工专业咨询服务，专家一对一解答您的企业问题。</p>
                        <p><span style="font-weight: bolder;">企业资质申报服务：</span>协助完成企业资质申报，包括材料准备和流程指导，确保申报成功率。</p>
                        <p><span style="font-weight: bolder;">长期合作优惠：</span>签订三年会员，免费申报企业资质服务或赠送一年会员。</p>
                        <p><span
                                style="font-weight: bolder;">人工咨询服务介绍：</span>我们的专家团队来自各个行业领域，拥有丰富的实战经验和深厚的专业知识，能够为您的企业提供量身定制的解决方案，帮助您应对各类挑战。
                        </p>
                    </div>

                </el-collapse-item>
                <el-collapse-item name="3">
                    <template #title>
                        <span style="font-weight: bolder;font-size: 17px;">V8 黑金会员</span>
                    </template>
                    <div>
                        <p><span style="font-weight: bolder;">全面服务开放：</span>享受公司提供的所有服务，包括最新推出的服务项目。</p>
                        <p><span style="font-weight: bolder;">专属优惠：</span>申报服务等享受8.8折优惠，深度定制服务方案。</p>
                        <p><span style="font-weight: bolder;">战略合作伙伴：</span>作为公司的战略合作伙伴，参与到新服务的测试与反馈，共同探索新的商业模式。</p>
                        <p><span
                                style="font-weight: bolder;">战略合作伙伴介绍：</span>作为战略合作伙伴，您将与我们共同面对市场变化，探索未来的服务创新。我们将提供优先的产品测试权、定制化服务解决方案，以及专属的商务会议，确保双方利益最大化。
                        </p>
                    </div>

                </el-collapse-item>
            </el-collapse>
        </div>
        <IndexFoot></IndexFoot>
    </div>
</template>
<style scoped lang="scss">
.member {
    .content {
        background-color: #fff;
        width: 70%;
        color: #333;
        font-size: 13px;
        margin: 0 auto;
        margin-top: 120px;
        box-sizing: border-box;
        padding: 1px 20px;
        h1{
            color: #003E9F;
        }
    }

}
</style>